import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";
import this_ from '@/main'

export default class Banner {
    constructor() {
        this.http = new HttpHandler();
    }

    /**
     * 获取配置
     * @returns {Promise<AxiosResponse<any>>}
     */
    getData() {
        let url = '/api/banner/getData';
        return this.http.get(url, null, HttpConstant.FORM_DATA_REQ);
    }

    /**
     * 修改配置
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    insData(data) {
        let url = '/api/banner/insData';
        return this.http.post(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    delBanner(id) {
        let url = `/api/banner/delBanner?id=${id}`;
        return this.http.delete(url, null, HttpConstant.FORM_DATA_REQ);
    }

    updateBanner(data) {
        let url = `/api/banner/updateBanner`;
        return this.http.put(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

}

