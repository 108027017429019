<template>
  <div style="margin-top: 20px;">
    <el-button @click="dialogVisible=true" style="float: right">新增</el-button>
    <el-table
        :data="tableData"
        border
        style="width: 100%">
      <el-table-column
          fixed
          prop="bearName"
          label="名称">
      </el-table-column>
      <el-table-column
          prop="bearUrl"
          label="地址">
      </el-table-column>
      <el-table-column width="150"
                       label="图片">
        <template slot-scope="scope">
          <img :src="'/api/resources/public/banner/' + scope.row['bearImg']" class="avatar" :fit="fit">
        </template>
      </el-table-column>
      <el-table-column
          prop="bearIndex"
          label="排序">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作">
        <template slot-scope="scope">
          <div>
            <el-button @click="show(scope.row)">查看</el-button>
          </div>
          <div>
            <el-button type="info" @click="updData(scope.row)">编辑</el-button>
          </div>
          <div>
            <el-button type="danger" @click="deleteBanner(scope.row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="90%"
        :before-close="handleClose">
      <el-form :model="fromData" ref="dynamicValidateForm" label-width="40px">
        <el-upload style="text-align: center"
                   class="avatar-uploader"
                   action="/api/file/saveToTemp"
                   :show-file-list="false"
                   :on-success="handleAvatarSuccess"
                   :before-upload="beforeAvatarUpload">
          <img v-if="tempImg" :src="tempImg" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-form-item label="名称" prop="usrName">
          <el-input v-model="fromData.bearName"></el-input>
        </el-form-item>
        <el-form-item prop="usrEmail" label="地址">
          <el-input v-model="fromData.bearUrl"></el-input>
        </el-form-item>
        <el-form-item prop="usrEmail" label="排序">
          <el-input-number v-model="fromData.bearIndex"  :min="0" :max="7" label="描述文字"/>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">取 消</el-button>
    <el-button type="primary" @click="submit">{{ operateType === 1 ? '新增' : '修改' }}</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import Banner from "../../request/banner";

export default {
  name: "banner",
  data() {
    return {
      sysAsk: new Banner(),
      tableData: null,
      dialogVisible: false,
      tempImg: '',
      fit: "contain",
      fromData: {
        "bearName": "",
        "bearUrl": "",
        "bearImg": "",
        "bearIndex": 1
      },
      operateType: 1, // 1为新增 0为修改
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    updData(data) {
      this.dialogVisible = true
      this.operateType = 0
      this.fromData = JSON.parse(JSON.stringify(data))
      this.fromData.bearImg = ""
      this.tempImg = '/api/resources/public/banner/' + data['bearImg']
    },
    getData() {
      this.sysAsk.getData().then(res => {
        this.tableData = res
      })
    },
    deleteBanner(data) {
      this.sysAsk.delBanner(data.baerId).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getData()
        }
      })
    },
    show(data) {
      this.dialogVisible = true
      this.fromData = data
      this.tempImg = '/api/resources/public/banner/' + data['bearImg']
    },
    cancel() {
      this.fromData = {}
      this.tempImg = ''
      this.dialogVisible = false
    },
    submit() {
      if (this.operateType === 1) {
        this.sysAsk.insData(this.fromData).then(res => {
          if (res.code == 200) {
            this.fromData = {}
            this.tempImg = ''
            this.dialogVisible = false
            this.getData()
          }
        })
      } else {
        this.sysAsk.updateBanner(this.fromData).then(res => {
          if (res.code == 200) {
            this.fromData = {}
            this.tempImg = ''
            this.dialogVisible = false
            this.getData()
          }
        })
      }
    },
    handleAvatarSuccess(res, file) {
      this.tempImg = URL.createObjectURL(file.raw);
      this.fromData.bearImg = res.msg
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG, PNG 格式!');
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isJPG && isLt10M;
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    }
  },
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
</style>